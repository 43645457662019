<loader [loading]="loading">
  <form [formGroup]="form">
    <div class="container">
      @if (edit || show) {
      <div class="row justify-content-end">
        @if (show) {
        <div class="col-md-2">
          <app-btn type="secondary" text="Edit" icon="edit" routerLink="../edit" [queryParams]="{ slug: code }" />
        </div>
        }
        @if (edit) {
        <div class="col-10 col-sm-4 col-md-3">
          <app-btn type="secondary" text="Cancel" icon="close" routerLink="../show" [queryParams]="{ slug: code }">
          </app-btn>
        </div>
        }
      </div>
      }
      <div class="row">
        <div class="col">
          <div class="form-group">
            <app-autocomplete type="select" [options]="systems" [form]="form" name="system" labelType="ct"
              valueField="code" label="Select System">
            </app-autocomplete>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <app-autocomplete type="select" [options]="allMenus" [form]="form" name="module" labelType="il"
              valueField="id" label="Select Module">
            </app-autocomplete>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <app-autocomplete type="select" [options]="selectedMenu" [form]="form" name="menu" labelType="il"
              valueField="id" label="Select Menu">
            </app-autocomplete>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <app-autocomplete type="select" [options]="otherLevelMenu" [form]="form" name="screenPath"
              [optionFormatter]="pathFormatter" valueField="id" (change)="selectMenu()" label="Select Screen Path">
            </app-autocomplete>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <app-input-basic type="textarea" [form]="form" name="description" label="Question">
            </app-input-basic>
          </div>
        </div>
      </div>
      <div class="row mt-3 justify-content-end content-area">
        <div class="content-header">
          <h4>Content</h4>
          @if (!show) {
          <div class="control-wrapper">
            <div class="add-control">
              <app-btn [mini]="true" [icon]="'add'" (click)="addContent()" [text]="'Text'" type="secondary" />
            </div>
            <div class="add-control">
              <app-btn [mini]="true" [icon]="'add'" (click)="addContent('TABLE')" [text]="'Table'" type="secondary">
              </app-btn>
            </div>
            <div class="add-control">
              <app-btn [mini]="true" [icon]="'add'" (click)="addContent('UPLOAD')" [text]="'Upload'" type="secondary">
              </app-btn>
            </div>
          </div>
          }
        </div>
        @for (control of controls.controls; track control; let i = $index) {

        <app-doc-cell-input [edit]="!show" [type]="control.value.contentType" [index]="i" [control]="control">
        </app-doc-cell-input>

        }
      </div>
      @if (!show) {
      <div class="row mt-3 justify-content-end">
        <div class="col-10 col-sm-4 col-md-3">
          <app-btn [group]="edit ? 'edit' : 'submit'" [text]="edit ? 'Update' : 'Submit'"
            (mclick)="edit ? onUpdate() : onSubmit()" [form]="form" [loading]="submitting" />
        </div>
      </div>
      }
    </div>

  </form>
</loader>
