import { Component, OnInit } from '@angular/core';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { getConfigMainMenu } from 'projects/evolutics-client-ui/src/app/configs/menu-configs/top-menu.config';
import { FKVP } from '@Shared/models/index.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditableTextCaseComponent } from '../../../../../evolutics-shared-lib/src/lib/Shared/components/editable-text-case/editable-text-case.component';
import { NgFor } from '@angular/common';
import { CardComponent } from '../../../../../evolutics-shared-lib/src/lib/Shared/components/card/card.component';

@Component({
    selector: 'app-dashboard-faq-view',
    templateUrl: './dashboard-faq-view.component.html',
    styleUrls: ['./dashboard-faq-view.component.scss'],
    standalone: true,
    imports: [
        CardComponent,
        NgFor,
        EditableTextCaseComponent,
        RouterLink,
        FontAwesomeModule,
    ],
})
export class DashboardFaqViewComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  menuItems = [];
  code: string = '';
  subs: any[];
  appLabels: FKVP[] = [
    { label: 'Module', key: 'module' },
    { label: 'Menu', key: 'menu' },
    { label: 'Screen Path', key: 'code' },
    // { label: 'Application Code', key: 'applicationCode' },
    // { label: 'Created By', key: 'createdBy' },
    // { label: 'Date Created', key: 'dateCreated' },
  ];
  constructor(public router: Router, private route: ActivatedRoute) {}

  /**
   * Recursively list sub menus in a menu item
   * @param x: Collection of Menu item
   * @returns List of subs
   */
  getSub(x: any) {
    const subs = [];
    if (x.hasSub) {
      return [
        ...x.subs.map((s) => {
          return this.getSub(s);
        }),
        x,
      ];
    } else {
      return x;
    }
  }

  /**
   * Get the top level parent of a menu item
   * @param x Menu item
   * @returns Parent menu item
   */
  getParent(x: any) {
    if (x?.parentID) {
      let p = this.subs.find(({ id }) => id == x?.parentID);
      return this.getParent(p);
    }
    return x;
  }

  /**
   * Returns the second level menu of an item
   * @param parent Parent Item
   * @param code Code of menu
   * @returns The menu under the top module of a parent
   */
  getMenu(parent, code) {
    let i;
    parent.subs.forEach((element, index) => {
      let r = this.getSub(element);
      r = r.flat(Infinity);
      if (r.findIndex(({ id }) => id == code) >= 0) {
        i = index;
        return;
      }
    });
    return parent.subs[i];
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap;
    this.code = queryParams.get('slug');
    this.init();
  }

  /**
   * Initialize menus and sub menus
   */
  init() {
    let menus = getConfigMainMenu();
    let subs = [];
    menus.forEach((x) => x.subs.forEach((t) => subs.push(this.getSub(t))));
    this.subs = [
      ...subs.flat(Infinity),
      ...menus,
      menus.map((x) => (x.hasSub ? x.subs : x)),
    ];
    this.subs = [...new Set(this.subs.flat(Infinity))];
    let s = this.subs.find(({ id }) => id == this.code);
    this.appLabels.find(
      ({ key }) => key == 'code'
    ).value = `${this.code} - ${s.label}`;
    let parent = this.getParent(s);
    this.appLabels.find(
      ({ key }) => key == 'module'
    ).value = `${parent.id} - ${parent.label}`;
    let menu = this.getMenu(parent, this.code);
    this.appLabels.find(
      ({ key }) => key == 'menu'
    ).value = `${menu.id} - ${menu.label}`;
  }
}
