<app-card [header]="'Basic Details'">
  <div class="row">
    @for (item of appLabels; track item) {
  <div class="col-md">
      <app-editable-text-case [formatter]="item.formatter" [stacked]="true" [label]="item.label" [name]="item.key"
        [coloredLbl]="true" [coloredVal]="false" [light]="true" [value]="item.value">
      </app-editable-text-case>
    </div>
}
  </div>
</app-card>
<div class="container">

  <div class="row">
    <div class="col-3">
      <nav id="navbar-example3" class="navbar navbar-light bg-light flex-column align-items-stretch p-3">
        <nav class="nav nav-pills flex-column">
          <a class="nav-link nav-head" routerLink="." fragment="item-1">Overview</a>
          <a class="nav-link nav-head d-flex justify-content-between" routerLink="." fragment="item-2">

            Agent
            <button class="badge bg-secondary shadow-none" (click)="agentMenu.hidden=!agentMenu.hidden ">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </a>
          <nav class="nav nav-pills flex-column" #agentMenu hidden>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-1-1">Agent</a>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-1-2">Agent Payment</a>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-1-2">Agent Type</a>
          </nav>
          <a class="nav-link nav-head d-flex justify-content-between" routerLink="." fragment="item-3">Client
            <button class="badge bg-secondary shadow-none" (click)="clientMenu.hidden=!clientMenu.hidden ">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </a>
          <nav #clientMenu hidden class="nav nav-pills flex-column">
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-3-1">Client</a>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-3-2">Client Details</a>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-3-2">Client Bank</a>
          </nav>
          <a class="nav-link nav-head d-flex justify-content-between" routerLink="." fragment="item-3">Policy
            <button class="badge bg-secondary shadow-none" (click)="policyMenu.hidden=!policyMenu.hidden ">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </a>
          <nav #policyMenu hidden class="nav nav-pills flex-column">
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-3-1">Policy</a>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-3-2">Policy Covers</a>
            <a class="nav-link ms-3 my-1 text-black-50" routerLink="." fragment="item-3-2">Policy Group</a>
          </nav>
        </nav>
      </nav>

    </div>
    <div class="col-9">
      <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-offset="0" tabindex="0">
        <h4 id="item-1">Item 1</h4>
        <p>...</p>
        <h5 id="item-1-1">Item 1-1</h5>
        <p>...</p>
        <h5 id="item-1-2">Item 1-2</h5>
        <p>...</p>
        <h4 id="item-2">Item 2</h4>
        <p>...</p>
        <h4 id="item-3">Item 3</h4>
        <p>...</p>
        <h5 id="item-3-1">Item 3-1</h5>
        <p>...</p>
        <h5 id="item-3-2">Item 3-2</h5>
        <p>...</p>
      </div>
    </div>
  </div>
</div>
